<template>
  <div class="container">
    <AppHeader />
    <keep-alive :include="'Breeding'">
      <router-view />
    </keep-alive>
    <NavigationMenu
      v-show="
        $route.name !== 'CryptoshoppiePage' &&
        $route.name !== 'Upgrade' &&
        $route.name !== 'Buy/sell page'
      "
    />
  </div>
</template>

<script>
import NavigationMenu from "@/components/navigation/NavigationMenu";
import AppHeader from "../components/containers/AppHeader";
export default {
  name: "Home",
  components: { AppHeader, NavigationMenu },
};
</script>
