<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.82C4.17 2 2 4.17 2 7.81V16.18C2 19.82 4.17 21.99 7.81 21.99H16.18C19.82 21.99 21.99 19.82 21.99 16.18V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#CAA8F5"
    />
    <path
      d="M13.9199 8.47957H8.76994L9.09994 8.14957C9.38994 7.85957 9.38994 7.37957 9.09994 7.08957C8.80994 6.79957 8.32994 6.79957 8.03994 7.08957L6.46994 8.65957C6.17994 8.94957 6.17994 9.42957 6.46994 9.71957L8.03994 11.2896C8.18994 11.4396 8.37994 11.5096 8.56994 11.5096C8.75994 11.5096 8.94994 11.4396 9.09994 11.2896C9.38994 10.9996 9.38994 10.5196 9.09994 10.2296L8.83994 9.96957H13.9199C15.1999 9.96957 16.2499 11.0096 16.2499 12.2996C16.2499 13.5896 15.2099 14.6296 13.9199 14.6296H8.99994C8.58994 14.6296 8.24994 14.9696 8.24994 15.3796C8.24994 15.7896 8.58994 16.1296 8.99994 16.1296H13.9199C16.0299 16.1296 17.7499 14.4096 17.7499 12.2996C17.7499 10.1896 16.0299 8.47957 13.9199 8.47957Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowBackSvg",
};
</script>

<style scoped></style>
