<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M2.77 10C2.34 10 2 9.66 2 9.23V6.92C2 4.21 4.21 2 6.92 2H9.23C9.66 2 10 2.34 10 2.77C10 3.2 9.66 3.54 9.23 3.54H6.92C5.05 3.54 3.54 5.06 3.54 6.92V9.23C3.54 9.66 3.19 10 2.77 10Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M21.23 10C20.81 10 20.46 9.66 20.46 9.23V6.92C20.46 5.05 18.94 3.54 17.08 3.54H14.77C14.34 3.54 14 3.19 14 2.77C14 2.35 14.34 2 14.77 2H17.08C19.79 2 22 4.21 22 6.92V9.23C22 9.66 21.66 10 21.23 10Z"
      fill="#CAA8F5"
    />
    <path
      d="M17.0799 22.0002H15.6899C15.2699 22.0002 14.9199 21.6602 14.9199 21.2302C14.9199 20.8102 15.2599 20.4602 15.6899 20.4602H17.0799C18.9499 20.4602 20.4599 18.9402 20.4599 17.0802V15.7002C20.4599 15.2802 20.7999 14.9302 21.2299 14.9302C21.6499 14.9302 21.9999 15.2702 21.9999 15.7002V17.0802C21.9999 19.7902 19.7899 22.0002 17.0799 22.0002Z"
      fill="#CAA8F5"
    />
    <path
      d="M9.23 22H6.92C4.21 22 2 19.79 2 17.08V14.77C2 14.34 2.34 14 2.77 14C3.2 14 3.54 14.34 3.54 14.77V17.08C3.54 18.95 5.06 20.46 6.92 20.46H9.23C9.65 20.46 10 20.8 10 21.23C10 21.66 9.66 22 9.23 22Z"
      fill="#CAA8F5"
    />
    <path
      d="M18.46 11.23H17.1H6.90002H5.54002C5.11002 11.23 4.77002 11.58 4.77002 12C4.77002 12.42 5.11002 12.77 5.54002 12.77H6.90002H17.1H18.46C18.89 12.77 19.23 12.42 19.23 12C19.23 11.58 18.89 11.23 18.46 11.23Z"
      fill="#CAA8F5"
    />
    <path
      d="M6.8999 13.94V14.27C6.8999 15.93 8.2399 17.27 9.8999 17.27H14.0999C15.7599 17.27 17.0999 15.93 17.0999 14.27V13.94C17.0999 13.82 17.0099 13.73 16.8899 13.73H7.1099C6.9899 13.73 6.8999 13.82 6.8999 13.94Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M6.8999 10.06V9.72998C6.8999 8.06998 8.2399 6.72998 9.8999 6.72998H14.0999C15.7599 6.72998 17.0999 8.06998 17.0999 9.72998V10.06C17.0999 10.18 17.0099 10.27 16.8899 10.27H7.1099C6.9899 10.27 6.8999 10.18 6.8999 10.06Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "ScanSvg",
};
</script>

<style scoped></style>
