var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('PageButton',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'CryptoshoppiePage' &&
      _vm.$route.name !== 'Upgrade' &&
      _vm.$route.name !== 'Game' &&
      _vm.$route.name !== 'CharacterBuy' &&
      _vm.$route.name !== 'SwapCoins' &&
      _vm.$route.name !== 'SellSystem' &&
      _vm.$route.name !== 'CharacterSell'
    ),expression:"\n      $route.name !== 'CryptoshoppiePage' &&\n      $route.name !== 'Upgrade' &&\n      $route.name !== 'Game' &&\n      $route.name !== 'CharacterBuy' &&\n      $route.name !== 'SwapCoins' &&\n      $route.name !== 'SellSystem' &&\n      $route.name !== 'CharacterSell'\n    "}],staticStyle:{"z-index":"100"},attrs:{"to":'scan'},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ScanSvg')]},proxy:true}])}),_c('PageButton',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name === 'CryptoshoppiePage' ||
      _vm.$route.name === 'Upgrade' ||
      _vm.$route.name === 'Game' ||
      _vm.$route.name === 'CharacterBuy' ||
      _vm.$route.name === 'SwapCoins' ||
      _vm.$route.name === 'SellSystem' ||
      _vm.$route.name === 'CharacterSell'
    ),expression:"\n      $route.name === 'CryptoshoppiePage' ||\n      $route.name === 'Upgrade' ||\n      $route.name === 'Game' ||\n      $route.name === 'CharacterBuy' ||\n      $route.name === 'SwapCoins' ||\n      $route.name === 'SellSystem' ||\n      $route.name === 'CharacterSell'\n    "}],staticStyle:{"z-index":"100"},on:{"click":_vm.goBack},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ArrowBackSvg')]},proxy:true}])}),_c('div',{staticClass:"coins"},[_c('div',{staticClass:"coins__wrap"},[_c('div',{staticClass:"coins__icon"},[_c('CoinGoldSvg')],1),_c('div',{staticClass:"coins__amount"},[_vm._v(" "+_vm._s(_vm.balanceBeforeDot(_vm.getBalanceSub))+"."),_c('span',[_vm._v(_vm._s(_vm.balanceAfterDot(_vm.getBalanceSub)))])])]),_c('div',{staticClass:"coins__wrap"},[_c('div',{staticClass:"coins__icon"},[_c('CoinSilverSvg')],1),_c('div',{staticClass:"coins__amount"},[_vm._v(" "+_vm._s(_vm.balanceBeforeDot(_vm.getBalanceIgm))+"."),_c('span',[_vm._v(_vm._s(_vm.balanceAfterDot(_vm.getBalanceIgm)))])])])]),_c('div',{staticClass:"header__btn"},[_c('PageButton',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$route.name !== 'CryptoshoppiePage' &&
        _vm.$route.name !== 'Upgrade' &&
        _vm.$route.name !== 'Game'
      ),expression:"\n        $route.name !== 'CryptoshoppiePage' &&\n        $route.name !== 'Upgrade' &&\n        $route.name !== 'Game'\n      "}],staticStyle:{"z-index":"100"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SettingsSvg')]},proxy:true}])}),_c('PageButton',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$route.name === 'CryptoshoppiePage' ||
        _vm.$route.name === 'Upgrade' ||
        _vm.$route.name === 'Game'
      ),expression:"\n        $route.name === 'CryptoshoppiePage' ||\n        $route.name === 'Upgrade' ||\n        $route.name === 'Game'\n      "}],on:{"click":_vm.goBack},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('Close')]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }