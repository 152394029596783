<template>
  <div class="main-nav-item" :class="{ active: isCurrent }" @click="click">
    <div class="main-nav-item__icon">
      <img
        :src="require(`@/assets/img/svg/nav-${icon}.svg`)"
        alt=""
        v-show="!isCurrent"
      />
      <img
        :src="require(`@/assets/img/svg/nav-${icon}-active.svg`)"
        alt=""
        v-show="isCurrent"
      />
    </div>
    <div class="main-nav-item__name">
      {{ $t(description) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    icon: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
