<template>
  <header class="header">
    <PageButton
      style="z-index: 100"
      :to="'scan'"
      v-show="
        $route.name !== 'CryptoshoppiePage' &&
        $route.name !== 'Upgrade' &&
        $route.name !== 'Game' &&
        $route.name !== 'CharacterBuy' &&
        $route.name !== 'SwapCoins' &&
        $route.name !== 'SellSystem' &&
        $route.name !== 'CharacterSell'
      "
    >
      <template v-slot:icon>
        <ScanSvg />
      </template>
    </PageButton>

    <PageButton
      style="z-index: 100"
      @click="goBack"
      v-show="
        $route.name === 'CryptoshoppiePage' ||
        $route.name === 'Upgrade' ||
        $route.name === 'Game' ||
        $route.name === 'CharacterBuy' ||
        $route.name === 'SwapCoins' ||
        $route.name === 'SellSystem' ||
        $route.name === 'CharacterSell'
      "
    >
      <template v-slot:icon>
        <ArrowBackSvg />
      </template>
    </PageButton>

    <div class="coins">
      <div class="coins__wrap">
        <div class="coins__icon">
          <CoinGoldSvg />
        </div>
        <div class="coins__amount">
          {{ balanceBeforeDot(getBalanceSub) }}.<span>{{
            balanceAfterDot(getBalanceSub)
          }}</span>
        </div>
      </div>

      <div class="coins__wrap">
        <div class="coins__icon">
          <CoinSilverSvg />
        </div>
        <div class="coins__amount">
          {{ balanceBeforeDot(getBalanceIgm) }}.<span>{{
            balanceAfterDot(getBalanceIgm)
          }}</span>
        </div>
      </div>
    </div>

    <div class="header__btn">
      <PageButton
        style="z-index: 100"
        v-show="
          $route.name !== 'CryptoshoppiePage' &&
          $route.name !== 'Upgrade' &&
          $route.name !== 'Game'
        "
      >
        <template v-slot:icon>
          <SettingsSvg />
        </template>
      </PageButton>

      <PageButton
        @click="goBack"
        v-show="
          $route.name === 'CryptoshoppiePage' ||
          $route.name === 'Upgrade' ||
          $route.name === 'Game'
        "
      >
        <template v-slot:icon>
          <Close />
        </template>
      </PageButton>
    </div>
  </header>
</template>

<script>
import PageButton from "../common/buttons/PageButton";
import ScanSvg from "../icons/ScanSvg";
import SettingsSvg from "../icons/SettingsSvg";
import CoinGoldSvg from "../icons/CoinGoldSvg";
import CoinSilverSvg from "../icons/CoinSilverSvg";
import Close from "../icons/Close";
import GoBackMixin from "@/mixins/common/GoBackMixin";
import FormatPriceMixin from "@/mixins/common/FormatPriceMixin";
import { mapGetters } from "vuex";
import ArrowBackSvg from "../icons/ArrowBackSvg";
export default {
  name: "AppHeader",
  mixins: [GoBackMixin, FormatPriceMixin],
  computed: {
    ...mapGetters({
      getBalanceIgm: "wallet/getBalanceIgm",
      getBalanceSub: "wallet/getBalanceSub",
    }),
  },
  components: {
    ArrowBackSvg,
    Close,
    CoinSilverSvg,
    CoinGoldSvg,
    SettingsSvg,
    ScanSvg,
    PageButton,
  },
};
</script>

<style scoped></style>
