<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M21.23 7.61998H15.69C15.31 7.61998 15 7.30998 15 6.91998C15 6.53998 15.31 6.22998 15.69 6.22998H21.23C21.61 6.22998 21.92 6.53998 21.92 6.91998C21.92 7.30998 21.61 7.61998 21.23 7.61998Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M6.46008 7.62023H2.77008C2.39008 7.62023 2.08008 7.31023 2.08008 6.93023C2.08008 6.55023 2.39008 6.24023 2.77008 6.24023H6.46008C6.84008 6.24023 7.15008 6.55023 7.15008 6.93023C7.15008 7.31023 6.84008 7.62023 6.46008 7.62023Z"
      fill="#CAA8F5"
    />
    <path
      d="M10.15 10.84C12.3149 10.84 14.07 9.08496 14.07 6.92C14.07 4.75504 12.3149 3 10.15 3C7.98502 3 6.22998 4.75504 6.22998 6.92C6.22998 9.08496 7.98502 10.84 10.15 10.84Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M21.2301 17.7701H17.5401C17.1601 17.7701 16.8501 17.4601 16.8501 17.0801C16.8501 16.7001 17.1601 16.3901 17.5401 16.3901H21.2301C21.6101 16.3901 21.9201 16.7001 21.9201 17.0801C21.9201 17.4601 21.6101 17.7701 21.2301 17.7701Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M8.31008 17.7701H2.77008C2.39008 17.7701 2.08008 17.4601 2.08008 17.0801C2.08008 16.7001 2.39008 16.3901 2.77008 16.3901H8.31008C8.69008 16.3901 9.00008 16.7001 9.00008 17.0801C9.00008 17.4601 8.69008 17.7701 8.31008 17.7701Z"
      fill="#CAA8F5"
    />
    <path
      d="M13.8502 21.0002C16.0151 21.0002 17.7702 19.2451 17.7702 17.0802C17.7702 14.9152 16.0151 13.1602 13.8502 13.1602C11.6852 13.1602 9.93018 14.9152 9.93018 17.0802C9.93018 19.2451 11.6852 21.0002 13.8502 21.0002Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "SettingsSvg",
};
</script>

<style scoped></style>
