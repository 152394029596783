<template>
  <nav
    class="main-nav"
    v-if="
      $route.name !== 'SwapCoins' &&
      $route.name !== 'CharacterBuy' &&
      $route.name !== 'SellSystem' &&
      $route.name !== 'CharacterSell'
    "
  >
    <NavigationItem
      v-for="(item, index) in menu"
      :key="index"
      :description="item.description"
      :icon="item.icon"
      :isCurrent="item.route === $route.name ? true : false"
      @click="$router.push({ name: item.route })"
    />
  </nav>
</template>

<script>
import NavigationItem from "@/components/navigation/NavigationItem";
export default {
  name: "NavigationMenu",
  components: { NavigationItem },
  data: () => ({
    menu: [
      {
        icon: "home",
        description: "nav-home",
        isCurrent: false,
        route: "Home",
      },
      {
        icon: "events",
        description: "nav-events",
        isCurrent: false,
        route: "Events",
      },
      {
        icon: "breeding",
        description: "nav-breeding",
        isCurrent: false,
        route: "Breeding",
      },
      {
        icon: "market",
        description: "nav-market",
        isCurrent: false,
        route: "Market",
      },
    ],
  }),
};
</script>
